import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import { DateTime } from 'luxon'

// import PoweredByImage from '../assets/powered-by.png'
import honorImage from '../assets/honor.png'

import {
  Logo,
  PoweredBox,
  PoweredBy,
  SubmitBtn,
  SubmitBtnVerify,
} from './CommonStylesForms'

const CompFormVerify = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
`
const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: white;
`
const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  border-bottom: 2px solid #ddd;
  margin-bottom: 30px;
  font-size: 1.9em;
  padding-top: 1em;
  padding-bottom: 3px;
`
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`
const VerChoiceWrapper = styled.span`
  display: flex;
  width: 50%;
  justify-content: space-evenly;
`
const WrapVerifiedCred = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
`

function Root(props) {
  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForVerification, setWaitingForVerification] = useState(false)
  const [connected, setConnected] = useState(false)
  const [chosenCred, setChosenCred] = useState('')
  const [verifiedRecoveryScore, setVerifiedRecoveryScore] = useState(null)
  const [verifiedStrainScore, setVerifiedStrainScore] = useState(null)

  const contactsState = useSelector((state) => state.contacts)
  const settingsState = useSelector((state) => state.settings)
  const logo = settingsState.logo

  useEffect(() => {
    if (
      !waitingForInvitation &&
      props.anonWebsocket &&
      props.readyForAnonMessages
    ) {
      props.sendRequest('INVITATIONS', 'CREATE_SINGLE_USE', {})
      setWaitingForInvitation(true)
    }
  }, [props.anonWebsocket, props.readyForAnonMessages])

  useEffect(() => {
    if (props.QRCodeURL !== '') {
      setWaitingForConnection(true)
    }
    if (contactsState.connection.state === 'active' && waitingForConnection) {
      setConnected(true)
    }
  }, [props.QRCodeURL, contactsState.connection, waitingForConnection])

  useEffect(() => {
    if (props.verificationStatus && props.verifiedCredential) {
      if (props.verifiedCredential.recovery_score) {
        setVerifiedRecoveryScore(props.verifiedCredential)
      } else if (props.verifiedCredential.strain_score) {
        setVerifiedStrainScore(props.verifiedCredential)
      } else {
        return
      }
    }
  }, [props.verificationStatus, props.verifiedCredential])

  const requestPresentations = (cred) => {
    setChosenCred(cred)
    switch (cred) {
      // case 'EMAIL':
      //   props.sendRequest('PRESENTATIONS', 'REQUEST', {
      //     connection_id: contactsState.connection.connection_id,
      //     schema_id: settingsState.schemas.SCHEMA_EMAIL,
      //     attributes: ['address'],
      //   })
      //   setWaitingForVerification(true)

      //   break
      // case 'EMPLOYMENT':
      //   props.sendRequest('PRESENTATIONS', 'REQUEST', {
      //     connection_id: contactsState.connection.connection_id,
      //     schema_id: settingsState.schemas.SCHEMA_EMPLOYMENT,
      //     attributes: [
      //       'employee_given_names',
      //       'employee_surnames',
      //       'employment_type',
      //       'employment_postal_code',
      //       'employment_country',
      //       'employment_address',
      //       'employment_region',
      //       'employment_city',
      //       'employment_start_date',
      //       'employment_role',
      //       'employment_role_description',
      //       'employer_address',
      //       'employer_postal_code',
      //       'employer_city',
      //       'employer_country',
      //       'employer_region',
      //       'employer_name',
      //     ],
      //   })
      //   setWaitingForVerification(true)

      //   break

      case 'RECOVERY_SCORE':
        props.sendRequest('PRESENTATIONS', 'REQUEST', {
          connection_id: contactsState.connection.connection_id,
          schema_id: settingsState.schemas.SCHEMA_RECOVERY_SCORE,
          attributes: [
            'recovery_score',
            'resting_heart_rate',
            'heart_rate_variability',
            'respiratory_rate',
            'sleep_duration',
            'data_date',
            'issuance_date',
            'device_type',
          ],
          discovered_features: contactsState.connection.discovered_features,
        })
        setWaitingForVerification(true)

        break
      case 'STRAIN_SCORE':
        props.sendRequest('PRESENTATIONS', 'REQUEST', {
          connection_id: contactsState.connection.connection_id,
          schema_id: settingsState.schemas.SCHEMA_STRAIN_SCORE,
          attributes: [
            'strain_score',
            'heart_rate',
            'calories_burned',
            'hr_zone_2_time',
            'hr_zone_3_time',
            'hr_zone_4_time',
            'sleep_duration',
            'perceived_exertion',
            'active_minutes',
            'data_date',
            'issuance_date',
            'device_type',
          ],
          discovered_features: contactsState.connection.discovered_features,
        })
        setWaitingForVerification(true)

        break
      case 'RECOVERY_AND_STRAIN':
        props.sendRequest('PRESENTATIONS', 'REQUEST_MULTIPLE', {
          connection_id: contactsState.connection.connection_id,
          discovered_features: contactsState.connection.discovered_features,
          schemas: [
            {
              schema_id: settingsState.schemas.SCHEMA_RECOVERY_SCORE,
              attributes: [
                'recovery_score',
                'resting_heart_rate',
                'heart_rate_variability',
                'respiratory_rate',
                'sleep_duration',
                'data_date',
                'issuance_date',
                'device_type',
              ],
            },
            {
              schema_id: settingsState.schemas.SCHEMA_STRAIN_SCORE,
              attributes: [
                'strain_score',
                'heart_rate',
                'calories_burned',
                'hr_zone_2_time',
                'hr_zone_3_time',
                'hr_zone_4_time',
                'sleep_duration',
                'perceived_exertion',
                'active_minutes',
                'data_date',
                'issuance_date',
                'device_type',
              ],
            },
          ],
        })
        setWaitingForVerification(true)

        break
      default:
        return
    }
  }

  const verifiedDisplay = () => {
    const dataDate = props.verifiedCredential.data_date.raw
      ? DateTime.fromSeconds(
          parseInt(props.verifiedCredential.data_date.raw)
        ).toLocaleString()
      : ''
    const issuanceDate = props.verifiedCredential.issuance_date.raw
      ? DateTime.fromSeconds(
          parseInt(props.verifiedCredential.issuance_date.raw)
        ).toLocaleString()
      : ''

    switch (chosenCred) {
      // case 'EMAIL':
      //   return <p>Email: {props.verifiedCredential.address.raw}</p>
      // case 'EMPLOYMENT':
      //   return (
      //     <span>
      //       <p>
      //         Given Names: {props.verifiedCredential.employee_given_names.raw}
      //       </p>
      //       <p>Surnames: {props.verifiedCredential.employee_surnames.raw}</p>
      //       <p>Employer Name: {props.verifiedCredential.employer_name.raw}</p>
      //     </span>
      //   )
      case 'RECOVERY_SCORE':
        return (
          <span>
            <p>Recovery Score: {props.verifiedCredential.recovery_score.raw}</p>
            <p>
              Resting Heart Rate:{' '}
              {props.verifiedCredential.resting_heart_rate.raw}
            </p>
            <p>
              Heart Rate Variability:{' '}
              {props.verifiedCredential.heart_rate_variability.raw}
            </p>
            <p>
              Respiratory Rate: {props.verifiedCredential.respiratory_rate.raw}
            </p>
            <p>Sleep Duration: {props.verifiedCredential.sleep_duration.raw}</p>
            <p>Data Date: {dataDate}</p>
            <p>Issuance Date: {issuanceDate}</p>
            <p>Device Type: {props.verifiedCredential.device_type.raw}</p>
          </span>
        )
      case 'STRAIN_SCORE':
        return (
          <span>
            <p>Strain Score: {props.verifiedCredential.strain_score.raw}</p>
            <p>Heart Rate: {props.verifiedCredential.heart_rate.raw}</p>
            <p>
              Calories Burned: {props.verifiedCredential.calories_burned.raw}
            </p>
            <p>
              HR Zone 2nd Time: {props.verifiedCredential.hr_zone_2_time.raw}
            </p>
            <p>
              HR Zone 3rd Time: {props.verifiedCredential.hr_zone_3_time.raw}
            </p>
            <p>
              HR Zone 4th Time: {props.verifiedCredential.hr_zone_4_time.raw}
            </p>
            <p>Sleep Duration: {props.verifiedCredential.sleep_duration.raw}</p>
            <p>
              Perceived Exertion:{' '}
              {props.verifiedCredential.perceived_exertion.raw}
            </p>
            <p>Active Minutes: {props.verifiedCredential.active_minutes.raw}</p>
            <p>Data Date: {dataDate}</p>
            <p>Issuance Date: {issuanceDate}</p>
            <p>Device Type: {props.verifiedCredential.device_type.raw}</p>
          </span>
        )
      case 'RECOVERY_AND_STRAIN':
        return (
          <WrapVerifiedCred>
            <span>
              <span style={{ marginBottom: '15px' }}>
                <h2>Verified Recovery Score</h2>
              </span>
              <p>Recovery Score: {verifiedRecoveryScore.recovery_score.raw}</p>
              <p>
                Resting Heart Rate:{' '}
                {verifiedRecoveryScore.resting_heart_rate.raw}
              </p>
              <p>
                Heart Rate Variability:{' '}
                {verifiedRecoveryScore.heart_rate_variability.raw}
              </p>
              <p>
                Respiratory Rate: {verifiedRecoveryScore.respiratory_rate.raw}
              </p>
              <p>Sleep Duration: {verifiedRecoveryScore.sleep_duration.raw}</p>
              <p>Data Date: {dataDate}</p>
              <p>Issuance Date: {issuanceDate}</p>
              <p>Device Type: {verifiedRecoveryScore.device_type.raw}</p>
            </span>
            <span>
              <span style={{ marginBottom: '15px' }}>
                <h2>Verified Strain Score</h2>
              </span>
              <p>Strain Score: {verifiedStrainScore.strain_score.raw}</p>
              <p>Heart Rate: {verifiedStrainScore.heart_rate.raw}</p>
              <p>Calories Burned: {verifiedStrainScore.calories_burned.raw}</p>
              <p>HR Zone 2nd Time: {verifiedStrainScore.hr_zone_2_time.raw}</p>
              <p>HR Zone 3rd Time: {verifiedStrainScore.hr_zone_3_time.raw}</p>
              <p>HR Zone 4th Time: {verifiedStrainScore.hr_zone_4_time.raw}</p>
              <p>Sleep Duration: {verifiedStrainScore.sleep_duration.raw}</p>
              <p>
                Perceived Exertion: {verifiedStrainScore.perceived_exertion.raw}
              </p>
              <p>Active Minutes: {verifiedStrainScore.active_minutes.raw}</p>
              <p>Data Date: {dataDate}</p>
              <p>Issuance Date: {issuanceDate}</p>
              <p>Device Type: {verifiedStrainScore.device_type.raw}</p>
            </span>
          </WrapVerifiedCred>
        )

      default:
        return <p>Credential Data could not be displayed</p>
    }
  }

  const handleVerifiedDisplay = () => {
    if (chosenCred === 'RECOVERY_AND_STRAIN') {
      if (verifiedRecoveryScore && verifiedStrainScore) {
        return (
          <>
            <HeaderVerify>Credentials Verified!</HeaderVerify>
            {verifiedDisplay()}
            <span style={{ margin: '0 0 20px 0'}}>
              <SubmitBtn disabled>Export Data</SubmitBtn>
            </span>
          </>
        )
      } else {
        return (
          <>
            <HeaderVerify>Waiting For Credentials!</HeaderVerify>
            <p>
              Present both your Recovery Score and Strain Score credentials...
            </p>
          </>
        )
      }
    } else {
      return (
        <>
          <HeaderVerify>Credentials Verified!</HeaderVerify>
          {verifiedDisplay()}
          <span style={{ margin: '0 0 20px 0'}}>
            <SubmitBtn disabled>Export Data</SubmitBtn>
          </span>
        </>
      )
    }
  }

  return (
    <>
      <CompFormVerify>
        <FormWrapper>
          <div className="avatar-container left-fold landing-col-6">
            {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
          </div>
          {connected ? (
            waitingForVerification ? (
              props.verificationStatus !== undefined ? (
                props.verificationStatus ? (
                  props.verifiedCredential ? (
                    handleVerifiedDisplay()
                  ) : (
                    <>
                      <HeaderVerify>Credentials Verified!</HeaderVerify>
                      <p>No Credential Data Was Passed</p>
                    </>
                  )
                ) : (
                  <>
                    <HeaderVerify>Verification Failed</HeaderVerify>
                    <p>
                      There was a problem verifying your credential. Please try
                      again or contact support by clicking on the link below:
                    </p>
                    <a href="https://indicio.tech/proven-support/">
                      <SubmitBtn>Contact Support</SubmitBtn>
                    </a>
                  </>
                )
              ) : (
                <>
                  <HeaderVerify>Verify your Credentials</HeaderVerify>
                  <p>
                    You will now receive a request on your mobile app to send
                    your credential to us for verification.
                  </p>
                </>
              )
            ) : (
              <>
                <HeaderVerify>Choose a credential to verify</HeaderVerify>
                <VerChoiceWrapper>
                  {/* <SubmitBtn onClick={() => requestPresentations('EMAIL')}>
                    Email
                  </SubmitBtn>
                  <SubmitBtn onClick={() => requestPresentations('EMPLOYMENT')}>
                    Employment
                  </SubmitBtn> */}
                  <SubmitBtnVerify
                    onClick={() => requestPresentations('RECOVERY_SCORE')}
                  >
                    Recovery Score
                  </SubmitBtnVerify>
                  <SubmitBtnVerify
                    onClick={() => requestPresentations('STRAIN_SCORE')}
                  >
                    Strain Score
                  </SubmitBtnVerify>
                  <SubmitBtnVerify
                    onClick={() => requestPresentations('RECOVERY_AND_STRAIN')}
                  >
                    Recovery & Strain Score
                  </SubmitBtnVerify>
                </VerChoiceWrapper>
              </>
            )
          ) : (
            <>
              <HeaderVerify>Verify your Credentials</HeaderVerify>
              <p>
                Simply scan the following QR code to begin the verification
                process:
              </p>
              {props.QRCodeURL ? (
                <div className="qr">
                  <p>
                    <QR value={props.QRCodeURL} size={256} renderAs="svg" />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </>
          )}
        </FormWrapper>
        <PoweredBox>
          <PoweredBy src={honorImage} alt="Honor Digital Rights Management" />
        </PoweredBox>
      </CompFormVerify>
    </>
  )
}

export default Root
